export const ACCEPTED_FILE_TYPES = Object.freeze(['image/jpeg', 'image/pjpeg', 'image/png']);
export const AUCTION_BANNER_DIMENSIONS = Object.freeze({ width: 700, height: 390 });
export const AUCTION_BANNER_RATIO = AUCTION_BANNER_DIMENSIONS.width / AUCTION_BANNER_DIMENSIONS.height;
export const AUTHENTICATION_COMPLETED_ACTION = 'AUTHENTICATION_COMPLETED_ACTION';
export const AUTHENTICATION_RETURN_PAGE_STORAGE = 'AUTHENTICATION_RETURN_PAGE';
export const BANNER_PREVIEW_STORAGE = 'BANNER_PREVIEW';
export const BID_TIMEOUT = 20000;
export const CANADA_CODE = 'CA';
export const COLOR_PREVIEW_STORAGE = 'COLOR_PREVIEW';
export const CURRENCY_CA = 'CAD';
export const CURRENCY_DEFAULT_LOCALE = 'fr-CA';
export const CURRENCY_US = 'USD';
export const CURRENCY_US_LOCALE = 'en-US';
export const DEFAULT_IMAGE_DIMENSIONS = Object.freeze({ width: 558, height: 507 });
export const DEFAULT_OVERTIME_DURATION = 5;
export const DEFAULT_OVERTIME_MAXIMUM_DURATION = 20;
export const DETAIL_IMAGE_DIMENSIONS = Object.freeze({ width: 768, height: 576 });
export const DETAIL_IMAGE_RATIO = DETAIL_IMAGE_DIMENSIONS.width / DETAIL_IMAGE_DIMENSIONS.height;
export const FACEBOOK_BANNER_DIMENSIONS = Object.freeze({ width: 1200, height: 630 });
export const FACEBOOK_BANNER_RATIO = FACEBOOK_BANNER_DIMENSIONS.width / FACEBOOK_BANNER_DIMENSIONS.height;
export const ITEM_BATCH_COUNT = 20;
export const ITEM_NAME_MAX_LENGTH = 90;
export const ITEM_NUMBER_MATH_DIGITS = 4;
export const LIST_IMAGE_DIMENSIONS = Object.freeze({ width: 280, height: 280 });
export const LIST_IMAGE_RATIO = LIST_IMAGE_DIMENSIONS.width / LIST_IMAGE_DIMENSIONS.height;
export const LOGO_PREVIEW_STORAGE = 'LOGO_PREVIEW';
export const MIN_PARTNER_TO_PLAY_CAROUSEL = 5;
export const NUMBER_COMPARISON_PRECISION = 0.03;
export const OVERTIME_MAXIMUM_DURATION_ALLOWED = 120;
export const QC_CODE = 'QC';
export const VT_CODE = 'VT';

//Doit correspondre avec les constantes dans /Models/ADB2CPolicies/BasePolicy.cs
export const COMPLETED_ACTION_LOGIN = "login";
export const COMPLETED_ACTION_SIGNUP = "signup";
export const POLICY_ORIGIN_AUCTION = "auction";
export const POLICY_ORIGIN_ORGANIZATION = "organization";
export const POLICY_ORIGIN_OWNER = "owner";
