export function threeWayComparison(func) {
  return (a, b) => {
    const aValue = func(a),
      bValue = func(b);
    if (aValue < bValue) {
      return -1;
    } else if (aValue > bValue) {
      return 1;
    } else {
      return 0;
    }
  };
}
export function threeWayStringComparison(func) {
  return (a, b) => {
    const aValue = func(a),
      bValue = func(b);
    if (aValue && (bValue === null || bValue === undefined)) {
      return -1;
    } else if ((aValue === null || aValue === undefined) && bValue) {
      return 1;
    } else if ((aValue === null || aValue === undefined) && (bValue === null || bValue === undefined)) {
      return 0;
    }

    // Permet de transformer les caractères accentués en caractères non accentués lors du tri
    const nomalizedAValue = aValue.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();
    const nomalizedBValue = bValue.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase();

    if (nomalizedAValue < nomalizedBValue) {
      return -1;
    } else if (nomalizedAValue > nomalizedBValue) {
      return 1;
    } else {
      return 0;
    }
  };
}

export function reversed(func) {
  return (a, b) => -func(a, b);
}
