export default class State {
  constructor() {
    this.appSettings = {
      appInsightsConnectionString: '',
      bidFunctionUrl: '',
      sendMessageFunctionUrl: '',
      viewedLotTrackingFunctionUrl: '',
      signalRUrl: '',
      authSignalRUrl: '',
      organizationSignalRUrl: '',
      newsFeedBidCount: -1,
      reCAPTCHAPublicKey: '',
      facebookId: '',
      stripePublicKey: '',
      donationButtonVisible: false,
      reassignLotVisible: false,
      stripePaymentEnabled: false,
      englishAvailable: false,
      KnowledgeBaseAvailable: false,
      suggestedPaymentDelayAfterClosure: 0,
      imageBaseUrl: '',
      imageBaseUrlSupplier: '',
      imageBaseUrlSupplierLogo: '',
      appUrl: '',
      participantPortalUrl: '',
      symbolicBidFunctionUrl: '',
      defaultBunlde: ''
    };
    // Champs utilisés pour la section <head>
    this.title = '';
    this.canonicalPath = '';
    // Champs fonctionnels
    this.hasNetworkAccess = true; // Si la page, le navigateur et l'OS ont accès à Internet actuellement
    this.isPageVisible = true; // Si l'onglet est actuellement visible dans le navigateur et que la fenêtre du navigateur n'est pas minimisée
    this.initialLoadingFinished = false;
    this.loading = false;
    this.interceptNavigation = null; // function (to, from, next)
    this.errors = [];
    this.adminErrors = [];
    this.isPreview = false;
    this.previewColor = '';
    this.previewLogoUrls = {};
    this.previewBanners = {};
    this.modals = {};
    // Utilisé pour la détection de messages SignalR
    this.signalRSequenceNumber = 0;
    this.connectionStaleSequence = 0;
    this.signalRConnectionFail = false;
  }
}
